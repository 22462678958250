// contactsTypes.ts

export const ContactsActionTypes = {
  API_RESPONSE_SUCCESS: 'API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR: 'API_RESPONSE_ERROR',
  GET_CONTACTS: 'GET_CONTACTS',
  UPDATE_CONTACT_TYPE: 'UPDATE_CONTACT_TYPE',
  SET_FOLLOWUP_CONFIG: 'SET_FOLLOWUP_CONFIG',
  GET_FOLLOWUP_CONFIG: 'GET_FOLLOWUP_CONFIG',
  SET_USER_TIME_ZONE: 'SET_USER_TIME_ZONE',  // New action type
};


export type  Contact_type = 'staff' | 'peer' | 'client' | 'unassigned'

// Contact structure
export interface Contact {
  contact_id: string;
  email: string;
  mobile_no: string;
  first_name: string;
  display_name: string;
  domain: string;
  user_id: string;
  contact_type: 'staff' | 'peer' | 'client' | 'unassigned' | 'self';
  scheduler_id: string;
}

// Follow-up config structure
export interface FollowUpConfig {
  rule_json: any;
  contact_type_rule: Record<string, any>;
  individual_contact_rule: Record<string, any>;
  tags_to_time_mapping: Record<string, any>;
}

// State structure for contacts reducer
export interface ContactsState {
  contacts: Record<string, Contact>;
  followUpConfig: FollowUpConfig;
  loading: boolean;
  error: string;
}

// contactsActions.ts
import { debugLogsTypes } from './debugLogsTypes';

// Action to handle API success
export const debugApiResponseSuccess = (actionType: string, data: any) => ({
  type: debugLogsTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// Action to handle API error
export const debugApiResponseError = (actionType: string, error: string) => ({
  type: debugLogsTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// Action to fetch all contacts
export const getLogs = () => ({
  type: debugLogsTypes.GET_LOGS,
});

export const getDevLogsFromFile = () => ({
  type: debugLogsTypes.GET_DEV_LOGS_FROM_FILE,
});

export const getDevLogsFromDb = () => ({
  type: debugLogsTypes.GET_DEV_LOGS_FROM_DB,
});

export const getEmailLogs = () => ({
  type: debugLogsTypes.GET_EMAIL_LOGS,
});

export const getSchedulerLogs = () => ({
  type: debugLogsTypes.GET_SCHEDULER_LOGS,
});


export const getActionLogs = () => ({
  type: debugLogsTypes.GET_ACTION_LOGS,
});

export const getAdminQueryResponse = (sqlQuery: any) => ({
  type: debugLogsTypes.GET_ADMIN_QUERY_RESPONSE,
  payload: { sqlQuery },
});

export const getAdminQueryResponseFromUserDb = (sqlQuery: any) => ({
  type: debugLogsTypes.GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB,
  payload: { sqlQuery },
});

export const getMessageResponseForAdmin = (data: any) => ({
  type: debugLogsTypes.GET_MESSAGE_RESPONSE_FOR_ADMIN,
  payload: { data },
});
// contactsApi.ts
import { APINodeClient } from './apiNodeCore';
import * as nodeUrl from './nodeUrls';

const api = new APINodeClient();

// Fetch contacts from the backend
export const getLogs = async () => {
  return api.get(nodeUrl.GET_LOGS);
};

export const getDevLogsFromFile = async () => {
  return api.get(nodeUrl.GET_DEV_LOGS_FROM_FILE);
};

export const getSchedulerLogs = async () => {
  return api.get(nodeUrl.GET_SCHEDULER_LOGS);
}

export const getDevLogsFromDb = async () => {
  return api.get(nodeUrl.GET_DEV_LOGS_FROM_DB);
};

export const getActionLogs = async () => {
  return api.get(nodeUrl.GET_ACTION_LOGS);
}

export const getEmailLogs = async () => {
  return api.get(nodeUrl.GET_EMAIL_LOGS_FROM_DB);
}

export const getAdminQueryResponse = async (sqlQuery: any) => {
  return api.create(nodeUrl.GET_ADMIN_QUERY_RESPONSE, sqlQuery);
};

export const getAdminQueryResponseFromUserDb = async (sqlQuery: any) => {
  return api.create(nodeUrl.GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB, sqlQuery);
};

export const getMessageResponseForAdmin = async (data: any) => {
  return api.create(nodeUrl.GET_MESSAGE_RESPONSE_FOR_ADMIN, data);
};

export const downloadDbFiles = async (): Promise<void> => {
  return api.triggerFileDownload('logs/downloadDbFiles');
};
import { GET_DEV_LOGS_FROM_FILE, GET_DEV_LOGS_FROM_DB, GET_ACTION_LOGS,  GET_EMAIL_LOGS_FROM_DB, GET_ADMIN_QUERY_RESPONSE, GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB, GET_SCHEDULER_LOGS } from "../../api/nodeUrls";

// contactsTypes.ts
export const debugLogsTypes = {
  API_RESPONSE_SUCCESS: 'API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR: 'API_RESPONSE_ERROR',
  GET_LOGS : 'GET_LOGS',
  GET_DEV_LOGS_FROM_FILE: 'GET_DEV_LOGS_FROM_FILE',
  GET_DEV_LOGS_FROM_DB: 'GET_DEV_LOGS_FROM_DB',
  GET_ACTION_LOGS: 'GET_ACTION_LOGS',
  GET_EMAIL_LOGS: 'GET_EMAIL_LOGS',
  GET_SCHEDULER_LOGS: 'GET_SCHEDULER_LOGS',
  GET_ADMIN_QUERY_RESPONSE: 'GET_ADMIN_QUERY_RESPONSE',
  GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB: 'GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB',
  GET_MESSAGE_RESPONSE_FOR_ADMIN:'GET_MESSAGE_RESPONSE_FOR_ADMIN'
};

// Follow-up config structure
export interface DebugLogs {
  logs: Array<Record<string, any>>;
  devLogs: Array<Record<string, any>>;
  devLogsFromDb: Array<Record<string, any>>;
  schedulerLogs: Array<Record<string, any>>;
  adminQueryResponse: Array<Record<string, any>>;
  adminQueryResponseFromUserDb: Array<Record<string, any>>;
  messageResponse: any;
  loading: boolean;
  error: any;
}

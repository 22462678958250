export enum AuthLoginActionTypes {
    API_RESPONSE_SUCCESS = "@@auth/login/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@auth/login/API_RESPONSE_ERROR",
  
    LOGIN_USER = "@@auth/login/LOGIN_USER",
    LOGOUT_USER = "@@auth/login/LOGOUT_USER",
    SOCIAL_LOGIN = "@@auth/login/SOCIAL_LOGIN",
    GOOGLE_VERIFY = "@@auth/login/GOOGLE_VERIFY",
    CAPTCHA_VERIFY = "@@auth/login/CAPTCHA_VERIFY",
    GMAIL_VERIFY = "@@auth/login/GMAIL_VERIFY",
    ADMIN_OTP_VERIFY = "@@auth/login/ADMIN_OTP_VERIFY",
    SEND_ADMIN_OTP = "@@auth/login/SEND_ADMIN_OTP",
    RESET_ADMIN_OTP_VERIFICATION = "@@auth/login/RESET_ADMIN_OTP_VERIFICATION"
  }
  
  export interface AuthLoginState {
    user: any;
    error: string;
    loading: boolean;
    isUserLogin?: boolean;
    isGmailPermissionProvided : boolean;
    isCaptchaVerified?: boolean;
    captchaRetries: number;
    isUserLogout: boolean;
    userName?: string;
    images?: string;
    isAdmin?: boolean;
    isAdminOTPVerified?: boolean;
  }
  
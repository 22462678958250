import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { debugLogsTypes } from './debugLogsTypes';
import {
  debugApiResponseSuccess,
  debugApiResponseError,
} from './debugLogsActions';
import {
  getLogs as getLogsApi, 
  getDevLogsFromFile as getDevLogFromFileApi,
  getDevLogsFromDb as getDevLogsFromDbApi,
  getActionLogs as getActionLogsApi,
  getEmailLogs as getEmailLogsApi,
  getSchedulerLogs as getSchedulerLogsApi,
  getAdminQueryResponse as getAdminQueryResponseApi,
  getAdminQueryResponseFromUserDb as getAdminQueryResponseFromUserDbApi,
  getMessageResponseForAdmin as getMessageResponseForAdminApi
} from '../../api/debugLog';
import { showSuccessNotification, showErrorNotification } from "../../../src/helpers/NotificationContext"




interface QueryApiResponse {
  data?: any;
  error?: boolean;
  message?: string;
}

// Saga to handle fetching contacts
function* getLogs() {
  try {
    // console.log("Saga: about to fetch GET logs through API");
    const response: Promise<any> = yield call(getLogsApi);
    // console.log("Saga: response from GET logs", response);
    yield put(
      debugApiResponseSuccess(debugLogsTypes.GET_LOGS, response)
    );
    yield call(showSuccessNotification, 'Logs Pulled successfully..');
  } catch (error: any) {
    console.error("Saga: error fetching logs", error);
    yield put(debugApiResponseError(debugLogsTypes.GET_LOGS, error));
    yield call(showErrorNotification, 'Failed to pull logs..');
  }
}


function* getDevLogsFromFile() {
  try {
    const response: Promise<any> = yield call(getDevLogFromFileApi);
    // console.log("Saga: response from GET logs from File", response);
    yield put(debugApiResponseSuccess(debugLogsTypes.GET_DEV_LOGS_FROM_FILE, response));
    yield call(showSuccessNotification, 'Logs Pulled successfully..');
  } catch (error: any) {
    console.error("Saga: error fetching logs  from File", error);
    yield put(debugApiResponseError(debugLogsTypes.GET_DEV_LOGS_FROM_FILE, error));
    yield call(showErrorNotification, 'Failed to pull logs..');
  }
}


function* getSchedulerLogs() {
  try {
    console.log('about to trigger scheduler logs api')
    const response: Promise<any> = yield call(getSchedulerLogsApi);
    console.log("Saga: response from GET scheduler logs from File", response);
    yield put(debugApiResponseSuccess(debugLogsTypes.GET_SCHEDULER_LOGS, response));
    yield call(showSuccessNotification, 'Logs Pulled successfully..');
  } catch (error: any) {
    console.error("Saga: error fetching GET scheduler logs  from File", error);
    yield put(debugApiResponseError(debugLogsTypes.GET_SCHEDULER_LOGS, error));
    yield call(showErrorNotification, 'Failed to pull logs..');
  }
}


function* getDevLogsFromDb() {
  try {
    const response: Promise<any> = yield call(getDevLogsFromDbApi);
    // console.log("Saga: response from GET logs from Db", response);
    yield put(debugApiResponseSuccess(debugLogsTypes.GET_DEV_LOGS_FROM_DB, response));
    yield call(showSuccessNotification, 'Logs Pulled successfully..');
  } catch (error: any) {
    console.error("Saga: error fetching logs  from Db", error);
    yield put(debugApiResponseError(debugLogsTypes.GET_DEV_LOGS_FROM_DB, error));
    yield call(showErrorNotification, 'Failed to pull logs..');
  }
}

function* getActionLogs() {
  try {
    const response: Promise<any> = yield call(getActionLogsApi);
    // console.log("Saga: response from GET logs from Db", response);
    yield put(debugApiResponseSuccess(debugLogsTypes.GET_ACTION_LOGS, response));
    yield call(showSuccessNotification, 'Logs Pulled successfully..');
  } catch (error: any) {
    console.error("Saga: error fetching logs  from Db", error);
    yield put(debugApiResponseError(debugLogsTypes.GET_ACTION_LOGS, error));
    yield call(showErrorNotification, 'Failed to pull logs..');
  }
}

function* getEmailLogs() {
  try {
    const response: Promise<any> = yield call(getEmailLogsApi);
    console.log("Saga: response from Email GET logs from Db", response);
    yield put(debugApiResponseSuccess(debugLogsTypes.GET_EMAIL_LOGS, response));
    yield call(showSuccessNotification, 'Logs Pulled successfully..');
  } catch (error: any) {
    console.error("Saga: error fetching logs  from Db", error);
    yield put(debugApiResponseError(debugLogsTypes.GET_EMAIL_LOGS, error));
    yield call(showErrorNotification, 'Failed to pull logs..');
  }
}


function* getAdminQueryResponse({ payload: { sqlQuery } }: any) {
  try {
    console.log("data in saga before api call", sqlQuery)
    const response: QueryApiResponse = yield call(getAdminQueryResponseApi, {sqlQuery});
    console.log("Saga: response from GET logs from Db", response);
    if (response.error) {
      console.error("Saga: error in response", response.message || "Unknown error");
      yield put(debugApiResponseError(debugLogsTypes.GET_ADMIN_QUERY_RESPONSE, response.message || "Error in response"));
      yield call(showErrorNotification, 'Failed to fetch query response..');
    } else {
      yield put(debugApiResponseSuccess(debugLogsTypes.GET_ADMIN_QUERY_RESPONSE, response));
      yield call(showSuccessNotification, 'Query Response Pulled successfully..');
    }
  } catch (error: any) {
    console.error("Saga: error fetching logs  from Db", error);
    yield put(debugApiResponseError(debugLogsTypes.GET_ADMIN_QUERY_RESPONSE, error));
    yield call(showErrorNotification, 'Failed to fetch query response..');
  }
}

function* getAdminQueryResponseFromUserDb({ payload: { sqlQuery } }: any) {
  try {
    console.log("data in saga before api call", sqlQuery)
    const response: QueryApiResponse = yield call(getAdminQueryResponseFromUserDbApi, {sqlQuery});
    console.log("Saga: response from GET logs from Db", response);
    if (response.error) {
      console.error("Saga: error in response", response.message || "Unknown error");
      yield put(debugApiResponseError(debugLogsTypes.GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB, response.message || "Error in response"));
      yield call(showErrorNotification, 'Failed to fetch query response..');
    } else {
      yield put(debugApiResponseSuccess(debugLogsTypes.GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB, response));
      yield call(showSuccessNotification, 'Query Response Pulled successfully..');
    }
  } catch (error: any) {
    console.error("Saga: error fetching logs  from Db", error);
    yield put(debugApiResponseError(debugLogsTypes.GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB, error));
    yield call(showErrorNotification, 'Failed to fetch query response..');
  }
}


function* getMessageResponseForAdmin({ payload: { data } }: any) {
  try {
    console.log("data in saga before api call", data)
    const response: QueryApiResponse = yield call(getMessageResponseForAdminApi, {data});
    console.log("Saga: response from getMessageResponseForAdmin from Db", response);
    if (response.error) {
      console.error("Saga: error fetching message response for admin", response.message || "Unknown error");
      yield put(debugApiResponseError(debugLogsTypes.GET_MESSAGE_RESPONSE_FOR_ADMIN, response.message || "Error in response"));
      yield call(showErrorNotification, 'Failed to process message');
    } else {
      yield put(debugApiResponseSuccess(debugLogsTypes.GET_MESSAGE_RESPONSE_FOR_ADMIN, response));
      yield call(showSuccessNotification, 'Message Processed successfully..');
    }
  } catch (error: any) {
    console.error("Saga: error fetching message response for admin", error);
    yield put(debugApiResponseError(debugLogsTypes.GET_MESSAGE_RESPONSE_FOR_ADMIN, error));
    yield call(showErrorNotification, 'Failed to process message');
  }
}




// Root saga to watch for the actions
export default function* debugLogsSaga() {
  yield takeLatest(debugLogsTypes.GET_LOGS, getLogs);
  yield takeLatest(debugLogsTypes.GET_DEV_LOGS_FROM_FILE, getDevLogsFromFile);
  yield takeLatest(debugLogsTypes.GET_DEV_LOGS_FROM_DB, getDevLogsFromDb);
  yield takeLatest(debugLogsTypes.GET_ACTION_LOGS, getActionLogs);
  yield takeLatest(debugLogsTypes.GET_EMAIL_LOGS, getEmailLogs);
  yield takeLatest(debugLogsTypes.GET_SCHEDULER_LOGS, getSchedulerLogs);
  yield takeLatest(debugLogsTypes.GET_ADMIN_QUERY_RESPONSE, getAdminQueryResponse);
  yield takeLatest(debugLogsTypes.GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB, getAdminQueryResponseFromUserDb);
  yield takeLatest(debugLogsTypes.GET_MESSAGE_RESPONSE_FOR_ADMIN, getMessageResponseForAdmin);
  
}


import { getAdminQueryResponse } from "./debugLogsActions";
import { debugLogsTypes, DebugLogs } from "./debugLogsTypes";


const INIT_STATE = {
    logs: [
      {
        date: "Wed, 23 Oct 2024 15:17:53 +0530",
        processed_status: "PROCESSED",
        link_id: 14,
        subject: "Re: Please do the suggested code fix for Mentor AI",
        body: "Please get this done by eod",
        from: "Anil Pal <admin@dkafka.com>",
        to: "Vikas Varfa <vikas@dkafka.com>",
        thread_id: "192b8c4d6c320d3d",
        labels:  "['label1', 'label2']",
        new_updated: "updated",
        action_id: 5,
        title: "Please do the suggested code fix for Mentor AI",
        allocated_date: "2024-10-23",
        deadline: "2024-10-23",
        priority: "Medium",
        status: "In Progress",
        status_update_commentary: "{\"update\": \"Anil Pal has requested Vikas Varfa to complete the task by the end of the day\"}",
        mail_origin_type: null,
        latest_action_status_update_date: "Wed, 23 Oct 2024 15:17:53 +0530",
      },
    ],
    devLogs: [],
    devLogsFromDb: [],
    emailLogs: [],
    actionLogs: [],
    schedulerLogs: [],
    adminQueryResponse: [],
    adminQueryResponseFromUserDb: [],
    messageResponse:{},
    loading: false,
    error: null,
  };


  const debugLogsReducer  = (state = INIT_STATE, action: any): DebugLogs => {
    switch (action.type) {
    // Success handler for API responses
    case debugLogsTypes.API_RESPONSE_SUCCESS:
      console.log("Reducer: success response for", action.payload.actionType);
      switch (action.payload.actionType) {
        case debugLogsTypes.GET_LOGS:
          // console.log("GET LOGS POST api success reducer triggered ", action.payload.data)
          return {
            ...state,
            loading:  false,
            logs: action.payload.data,
          };
          case debugLogsTypes.GET_DEV_LOGS_FROM_FILE:
            // console.log("GET Logs from FILE POST api success reducer triggered ", action.payload.data)
            return {
              ...state,
              loading:  false,
              devLogs: action.payload.data.combinedLog,
            };
          case debugLogsTypes.GET_DEV_LOGS_FROM_DB:
            // console.log("GET Logs from DB POST api success reducer triggered ", action.payload.data)
            return {
              ...state,
              loading:  false,
              devLogsFromDb: action.payload.data,
            };
          case debugLogsTypes.GET_ACTION_LOGS:
            // console.log("GET Logs from DB POST api success reducer triggered ", action.payload.data)
            return {
              ...state,
              loading:  false,
              devLogsFromDb: action.payload.data,
            };
          case debugLogsTypes.GET_EMAIL_LOGS:
              // console.log("GET Logs from DB Emil POST api success reducer triggered ", action.payload.data)
              return {
                ...state,
                loading:  false,
                devLogsFromDb: action.payload.data,
              };    
          case debugLogsTypes.GET_SCHEDULER_LOGS:
                console.log("GET Logs from FILE POST scheduler api success reducer triggered ", action.payload.data.data)
              return {
                ...state,
                loading:  false,
                schedulerLogs: action.payload.data.data,
              };     
          case debugLogsTypes.GET_ADMIN_QUERY_RESPONSE:
            // console.log("GET ADMIN_QUERY_RESPONSE POST api success reducer triggered ", action.payload)
            if(action.payload.data.error){
              return {
                ...state,
                adminQueryResponse: []
              }
            } else {
              return {
                ...state,
                loading:  false,
                adminQueryResponse: action.payload.data,
              };
            }
          case debugLogsTypes.GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB:
          // console.log("GET ADMIN_QUERY_RESPONSE_USER_DB POST api success reducer triggered ", action.payload)
          if(action.payload.data.error){
            return {
              ...state,
              adminQueryResponseFromUserDb: []
            }
          } else {
            return {
              ...state,
              loading:  false,
              adminQueryResponseFromUserDb: action.payload.data,
            };
          }

          case debugLogsTypes.GET_MESSAGE_RESPONSE_FOR_ADMIN:
            console.log("GET GET_MESSAGE_RESPONSE_FOR_ADMIN POST api success reducer triggered ", action.payload)
            if(action.payload.data.error){
              return {
                ...state,
                messageResponse: {}
              }
            } else {
              return {
                ...state,
                loading:  false,
                messageResponse: action.payload.data,
              };
            }
            
            
        default:
          return { ...state };
      } 
      
    // Error handler for API responses
    case debugLogsTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case debugLogsTypes.GET_ADMIN_QUERY_RESPONSE:
          console.log("Reducer: error response GET_ADMIN_QUERY_RESPONSE:", action.payload);
          return {
            ...state,
            loading:  false,
            adminQueryResponse: [],
          }; 
        case debugLogsTypes.GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB:
          console.log("Reducer: error response GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB:", action.payload);
          return {
            ...state,
            loading:  false,
            adminQueryResponseFromUserDb: [],
          };

        case debugLogsTypes.GET_MESSAGE_RESPONSE_FOR_ADMIN:
          console.log("Reducer: error response GET_MESSAGE_RESPONSE_FOR_ADMIN:", action.payload);
          return {
            ...state,
            loading:  false,
            messageResponse: {},
          };
        default:
          console.log("Reducer: error response:", action.payload);
          return {
            ...state,
            loading: false,
            error: typeof action.payload.error === 'string' ? action.payload.error : "An error occurred in debugs log reducer response",
          };
      }

      // Actions to set loading state before API call
    case debugLogsTypes.GET_LOGS:
      // console.log("get LOGS pre api reducer is triggered ")
      return { ...state };

    case debugLogsTypes.GET_DEV_LOGS_FROM_FILE:
      // console.log("get Logs from FILE pre api reducer is triggered ")
      return { ...state };

    case debugLogsTypes.GET_DEV_LOGS_FROM_DB:
      // console.log("get Logs from Db pre api reducer is triggered ")
      return { ...state };
      

    case debugLogsTypes.GET_ACTION_LOGS:
      // console.log("get Logs from Db pre api reducer is triggered ")
      return { ...state };
      
    case debugLogsTypes.GET_EMAIL_LOGS:
        // console.log("get Logs from Db Email logs pre api reducer is triggered ")
        return { ...state };
    
    case debugLogsTypes.GET_SCHEDULER_LOGS:
          console.log("get Logs from FILE scheduler logs pre api reducer is triggered")
          return { ...state };
    
    case debugLogsTypes.GET_ADMIN_QUERY_RESPONSE:
      // console.log("get GET_ADMIN_QUERY_RESPONSE pre api reducer is triggered ", action.payload)
      return { ...state }; 
    
    case debugLogsTypes.GET_ADMIN_QUERY_RESPONSE_FROM_USER_DB:
      // console.log("get GET_ADMIN_QUERY_RESPONSE pre api reducer is triggered ", action.payload)
      return { ...state };
      
    case debugLogsTypes.GET_MESSAGE_RESPONSE_FOR_ADMIN:
      console.log("get GET_MESSAGE_RESPONSE_FOR_ADMIN pre api reducer is triggered ", action.payload)
      return { ...state };

    default:
      return { ...state };

    }
  };

  export default debugLogsReducer;
// contactsActions.ts

import { ContactsActionTypes } from './contactTypes';

// Action to handle API success
export const contactsApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContactsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// Action to handle API error
export const contactsApiResponseError = (actionType: string, error: string) => ({
  type: ContactsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// Action to fetch all contacts
export const getContacts = () => ({
  type: ContactsActionTypes.GET_CONTACTS,
});

// Action to update a contact's type
export const updateContactType = (id: string, contact_type: 'staff' | 'peer' | 'client' | 'unassigned') => ({
  type: ContactsActionTypes.UPDATE_CONTACT_TYPE,
  payload: { id, contact_type },
});

// Action to set the follow-up configuration
export const setFollowUpConfig = (configString: string) => ({
  type: ContactsActionTypes.SET_FOLLOWUP_CONFIG,
  payload: { configString },
});

// Action to fetch the follow-up configuration
export const getFollowUpConfig = () => ({
  type: ContactsActionTypes.GET_FOLLOWUP_CONFIG,
});

// Action to set user time zone configuration
export const setUserTimeZone = (data: any) => ({
  type: ContactsActionTypes.SET_USER_TIME_ZONE,
  payload: { data },
});

